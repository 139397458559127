import axios from 'axios'

export const isDevMode = process.env.NODE_ENV === 'development'
export const isFrame = window.self !== window.top
export const isAccept = process.env.APP_MODE === 'notframe'

export const imageURL = isDevMode
  ? process.env.VUE_APP_API_URL
  : `${location.origin}/api/v1/content`

export const api = axios.create({
  baseURL:
    isDevMode
      ? process.env.VUE_APP_API_URL
      : `${location.origin}/api/v1/content/`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})

const ratingEndpoint = axios.create({
  baseURL:
    isDevMode
      ? process.env.VUE_APP_RATING_URL
      : `${location.origin}/service_rating/`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})

export const getContent = () => {
  return api.get('/locations')
}

export const getAddFloorParams = () => {
  return api.get('/rooms')
}

export const getSystems = () => {
  return api.get('/system')
}

export const getConstructionBySystemId = (id, calcType) => {
  return api.get(`/construction/system/${id}/${calcType}`)
}

export const getOptionalSystem = (data) => {
  return api.post('/optional', JSON.stringify(data))
}

export const getRequiredIndex = (data) => {
  return api.post('/required/index', JSON.stringify(data))
}

export const getDrawing = (data) => {
  return api.post('/drawing', JSON.stringify(data))
}

export const getSuitableSystems = (data) => {
  return api.post('/satisfaction', JSON.stringify(data))
}

export const getCalculationResult = (data, constructionTypeId = null) => {
  return api.post(constructionTypeId ? `/calc/${constructionTypeId}/pro` : '/calc/pro', JSON.stringify(data))
}

export const getDocument = (data) => {
  return api.post('/reports/doc', JSON.stringify(data))
}

export const getPdf = (data) => {
  return api.post('/reports/pdf', JSON.stringify(data), { responseType: 'blob' })
}

export const sendRating = () => {
  let formData = new FormData()
  return ratingEndpoint.post('/vote', formData)
}
