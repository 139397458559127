import { CalcTypes, ConstructionTypes } from '@/utils/types'

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export function encodeSVG(svg) {
  return svg.replace(/#/g, '%23').replace(/</g, '%3C').replace(/>/g, '%3E')
}

export const transparentFavIcon = encodeSVG(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 18"><path fill="none" d="M-1-1H23V19H-1z"/><path stroke="null" stroke-opacity="null" stroke-width="null" fill="transparent" d="M0-.7H22.1V18.1H0z"/></svg>`)

export function setInputWidth(element) {
  const length = element.getAttribute('placeholder')?.length
  element.setAttribute('size', length)
}

export function setContainerHeight() {
  const selectors = ['.header', '.nav', '.navigation__slider', '.tabs', '.wizard__wrapper', '.wizard__title', '.wizard-footer']
  const margins = ['marginTop', 'marginBottom']
  const paddings = ['paddingTop', 'paddingBottom']

  let height = 0

  selectors.forEach((selector) => {
    const element = document.querySelector(selector)
    const isContainer = selector === '.wizard__wrapper'
    const offsets = isContainer ? [...paddings, ...margins] : [...paddings]

    if (element) {
      offsets.forEach((offset) => {
        height += parseFloat(window.getComputedStyle(element)[offset])
      })
      height += !isContainer ? element.getBoundingClientRect().height : 0
    }
  })

  return `${window.innerHeight - height}px`
}

export const initialVisibleItems = 3

export const actions = {
  next: 'next',
  back: 'back',
  toStart: 'toStart',
  switchSector: 'switch',
  editSector: 'edit'
}

export function getReportStructure(sector) {
  const type = sector.soundIsolation?.soundIsolation?.id && sector.algorithm === CalcTypes.pro
    ? ConstructionTypes.floor : ConstructionTypes.wall
  const commonSet = { structure: 'base', screed: 'screed' }
  const params = {
    [CalcTypes.lite]: {
      [ConstructionTypes.floor]: commonSet,
      [ConstructionTypes.wall]: commonSet
    },
    [CalcTypes.pro]: {
      [ConstructionTypes.floor]: commonSet,
      [ConstructionTypes.wall]: { structure: 'facing', screed: 'isolation' }
    }
  }

  const structureParam = params[sector.algorithm][`${type}`].structure
  const screedParam = params[sector.algorithm][`${type}`].screed

  const requestBody = {
    title: sector.name,
    optionSystemId: sector.construction?.id || null,
    calc: {
      calcType: sector.algorithm,
      systemId: sector.system?.id,
      structure: {
        id: sector.base.base?.id ?? sector.facing.facing?.id ?? null,
        density: sector.base.density ?? sector.facing.density ?? 0,
        thickness: sector[structureParam].thickness || 0,
        qtySheet: sector.facing.sheets ?? null
      },
      screed: {
        id: sector.screed.screed?.id ?? sector.isolation.isolation?.id ?? null,
        thickness: sector[screedParam]?.thickness?.value || sector[screedParam]?.thickness || 0,
        count: sector.isolation?.thickness?.count ?? null
      },
      location: {
        locationId: sector.constructionType.location.id,
        rating: sector.constructionType.rating
      }
    }
  }

  if (sector.soundIsolation?.soundIsolation?.id) {
    requestBody.calc.sound = {
      id: sector.soundIsolation?.soundIsolation?.id,
      thickness: sector.soundIsolation?.thickness
    }
  }

  return requestBody
}

export function getSectorConstructionTypeId(constructionTypes, sector) {
  return constructionTypes.find((type) => type.constructionType === sector.constructionType.type)?.constructionId
}

export function getRequestCalcDataRPO(sector, type) {
  const commonData = {
    systemId: sector.system?.id,
    location: {
      locationId: sector.constructionType.location?.id,
      rating: sector.constructionType?.rating
    }
  }

  const typedData = {
    [ConstructionTypes.floor]: {
      structure: {
        id: sector.base.base?.id || null,
        density: sector.base.density || null,
        thickness: sector.base.thickness || null
      },
      screed: {
        id: sector.screed.screed?.id || null,
        thickness: sector.screed?.thickness || null
      },
      sound: {
        id: sector.soundIsolation?.soundIsolation?.id || null,
        thickness: sector.soundIsolation?.thickness || null
      },
      calcType: sector.algorithm,
      technicalRoomId: null
    },
    [ConstructionTypes.wall]: {
      structure: {
        id: sector.facing?.facing?.id || null,
        density: sector.facing?.density || null,
        thickness: sector.facing?.thickness || null,
        qtySheet: sector.facing?.sheets || null
      },
      screed: {
        id: sector.isolation?.isolation?.id || null,
        thickness: sector.isolation?.thickness
          ? parseFloat(sector.isolation?.thickness?.title) || sector.isolation?.thickness
          : null,
        count: sector.isolation?.thickness?.count ?? 1
      }
    }
  }

  return { ...commonData, ...typedData[type ?? ConstructionTypes.wall] }
}
